import { Injectable } from "@angular/core";

@Injectable()
export class apiList {

  login: string = "api/v1/admin/login";
  regiser: string = "api/v1/admin/regiser";
  logout: string = "api/v1/admin/logout";
  getProfile: string = "api/v1/admin/getProfile";
  updateProfile: string = "api/v1/admin/updateProfile";
  changePassword: string = "api/v1/admin/changePassword";
  resetPassword: string = "api/v1/admin/resetPassword";
  forgotPassword: string = "api/v1/admin/forgotPassword";
  uploadFile: string = "api/v1/admin/uploadFile";
  addCms: string = "api/v1/admin/addCms";
  updateCms: string = "api/v1/admin/updateCms";
  getCms: string = "api/v1/admin/getCms";
  deleteCms: string = "api/v1/admin/deleteCms";
  addLandingPage: string = "api/v1/admin/addLandingPage";
  getLandingPage: string = "api/v1/admin/getLandingPage";
  addCategory: string = "api/v1/admin/addCategory";
  editCategory: string = "api/v1/admin/editCategory";
  getCategory: string = "api/v1/admin/getCategory";
  deleteCategory: string = "api/v1/admin/deleteCategory";
  addSubCategory: string = "api/v1/admin/addSubCategory";
  editSubCategory: string = "api/v1/admin/editSubCategory";
  getSubCategory: string = "api/v1/admin/getSubCategory";
  deleteSubCategory: string = "api/v1/admin/deleteSubCategory";
  addProduct: string = "api/v1/admin/addProduct";
  editProduct: string = "api/v1/admin/editProduct";
  getProduct: string = "api/v1/admin/getProduct";
  deleteProduct: string = "api/v1/admin/deleteProduct";
  addReviews: string = "api/v1/admin/addReviews";
  editReviews: string = "api/v1/admin/editReviews";
  getReviews: string = "api/v1/admin/getReviews";
  getUserReviews: string = "api/v1/admin/getUserReviews";
  deleteReviews: string = "api/v1/admin/deleteReviews";
  addUnits: string = "api/v1/admin/addUnits";
  getUnits: string = "api/v1/admin/getUnits";
  editUnits: string = "api/v1/admin/editUnits";
  deleteUnits: string = "api/v1/admin/deleteUnits";
  editSetting: string = "api/v1/admin/editSetting";
  getSetting: string = "api/v1/admin/getSetting";
  editOrder: string = "api/v1/admin/editOrder";
  getOrder: string = "api/v1/admin/getOrder";
  addUsers: string = "api/v1/admin/addUsers";
  editUsers: string = "api/v1/admin/editUsers";
  updateUsers: string = "api/v1/admin/updateUsers";
  getUsers: string = "api/v1/admin/getUsers";
  deleteUsers: string = "api/v1/admin/deleteUsers";
  addCoupons: string = "api/v1/admin/addCoupons";
  editCoupons: string = "api/v1/admin/editCoupons";
  getCoupons: string = "api/v1/admin/getCoupons";
  deleteCoupons: string = "api/v1/admin/deleteCoupons";
  getTags: string = "api/v1/admin/getTags";
  addTags: string = "api/v1/admin/addTags";
  editTags: string = "api/v1/admin/editTags";
  deleteTags: string = "api/v1/admin/deleteTags";
  sampleCsv: string = "api/v1/admin/sampleCsv";
  importCsv: string = "api/v1/admin/importCsv";
  sampleUserCsv: string = "api/v1/admin/sampleUserCsv";
  importUserCsv: string = "api/v1/admin/importUserCsv";
  exportProductCsv: string = "api/v1/admin/productCsv";
  getDashboard: string = "api/v1/admin/getDashboard";

  
}
