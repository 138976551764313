import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MaterialModule } from './material/material.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Overlay } from '@angular/cdk/overlay';
import { LocationStrategy, PathLocationStrategy, CurrencyPipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatDialog } from '@angular/material';
import { apiList } from './services/https/api-list';
import { HttpInterceptorService } from './services/interceptor/http-interceptor.service';
// import { AgmCoreModule } from '@agm/core';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    MaterialModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyDe5zDWD2AWyXYxd-plbHgBETx09jB0UcE',
    //   libraries: ["drawing", "places", "geometry"]
    // })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
    {provide: LocationStrategy, useClass: PathLocationStrategy},
    apiList,
    CurrencyPipe,
    // NgxUiLoaderService,
    MatDialog,
    Overlay,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
